<app-alert></app-alert>

<app-chart-filter
  [visible]="false"
  (onDateChange)="onDateChange($event)"
  (onAttributeChange)="onAttributeChange($event)"
  (onLocationChange)="onLocationChange($event)"
  (onFrequenciesChange)="onFrequenciesChange($event)"
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]">
</app-chart-filter>
<div>
  <app-sensor-charts
    [isFavOnly]="true"
    [operationTypes]="[OperationType.OPTIMAL_ATTRIBUTE,OperationType.DOWNLOAD_AUTO,OperationType.DOWNLOAD_MANUAL, OperationType.M_MEASURE]"
    [chartFilterChangeSubjectSensor]="chartFilterChangeSubjectSensor"
    [chartFilterFrequenciesChangeSubjectSensor]="chartFilterFrequenciesChangeSubjectSensor"
    [chartFilterAttributeChangeSubjectSensor]="chartFilterAttributeChangeSubjectSensor">
  </app-sensor-charts>
</div>
