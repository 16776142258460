<p-dialog header="{{ 'hydroponics.alert.error_message' | translate }}"
          (onHide)="actionOnClose()"
          [(visible)]="visible"
          [style]="{width: '85vw'}"
          [maximizable]="true"
          [blockScroll]="true"
          [modal]="true">
  <div class="card">
    <p-table
      [paginator]="true"
      paginatorDropdownAppendTo="body"
      [lazy]="true"
      [rows]="rows"
      [first]="first"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="pageSizeOptions"
      (onPage)="pageChanged($event)"
      [value]="alerts"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'hydroponics.alert.alertTable.time' | translate }}</th>
          <th>Device name</th>
          <th>Description</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-alertNotification>
        <tr>
          <td>{{ alertNotification.alert.creationDate | date:'Y-MM-dd H:m:S' }}</td>
          <td>{{ alertNotification.alert.deviceName }}</td>
          <td>{{ alertNotification.alert.description }}</td>
          <td>
            <p-button class="hide-alert" icon="pi pi-times"
                      [disabled]="isloading"
                      (click)="hideAlert(alertNotification.id)">
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
