import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {SensorDataType} from "../sensor-data/sensor-data.component";
import {SensorManagementImplService} from "../../../../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import {SaveNaSensorCalibrationDataRequest} from "../../../../../generated/hydroponics-device-management-api/models/save-na-sensor-calibration-data-request";
import {CalibrationPointDto} from "../../../../../generated/hydroponics-device-management-api/models/calibration-point-dto";
import {MatTableDataSource} from "@angular/material/table";
import {NaSensorCalibrationDto} from "../../../../../generated/hydroponics-device-management-api/models/na-sensor-calibration-dto";
import {DateService} from "../../../../util/date.service";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {SensorboardManualMeasureComponent} from "../sensorboard-manual-measure/sensorboard-manual-measure.component";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-calibration',
  templateUrl: './calibration.component.html',
  styleUrls: ['./calibration.component.sass']
})
export class CalibrationComponent implements OnInit {
  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  first = 0;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  protected refMeasure: DynamicDialogRef | undefined;
  chosedDate: Date;
  dataSource: NaSensorCalibrationDto[] = [];
  public saveNaSensorCalibrationDataRequest: SaveNaSensorCalibrationDataRequest;
  calculatedValues: number[] = [];
  averageValues: number[] = [];

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public dialogService: DialogService,
              private sensorManagementService: SensorManagementImplService,
              public dateService: DateService) {
  }

  ngOnInit() {
    this.chosedDate = new Date(Date.now());

    let firstCalibrationPointDto = {} as CalibrationPointDto;
    let secondCalibrationPointDto = {} as CalibrationPointDto;

    this.saveNaSensorCalibrationDataRequest = {
      calibrationPoints: [
        firstCalibrationPointDto,
        secondCalibrationPointDto
      ],
      dataType: this.config.data.dataType.toUpperCase()
    };


    switch (this.config.data.dataType) {
      case "pH": {
        this.saveNaSensorCalibrationDataRequest.calibrationPoints[0].valueX = 4;
        this.saveNaSensorCalibrationDataRequest.calibrationPoints[1].valueX = 9;
        break;
      }
      case "EC": {
        this.saveNaSensorCalibrationDataRequest.calibrationPoints[0].valueX = 1;
        this.saveNaSensorCalibrationDataRequest.calibrationPoints[1].valueX = 29;
        break;
      }
    }

    this.getCalibrations();
  }

  saveCalibration() {
    this.sensorManagementService.saveCalibrationData({
      deviceId: this.config.data.deviceId,
      body: this.saveNaSensorCalibrationDataRequest,
      calibrationDate: DateService.getLocalISOString(new Date(this.chosedDate))
    }).subscribe(e =>{
      this.getCalibrations();
    });
  }

  public measureToCalibration(index: number){
    this.refMeasure = this.dialogService.open(SensorboardManualMeasureComponent, {data: {deviceId: this.config.data.deviceId, dataType: this.config.data.dataType}});
    this.refMeasure.onClose.subscribe((result) => {
      if (result) {
        this.saveNaSensorCalibrationDataRequest.calibrationPoints[index].valueY = result.averageAttributeValue;
        this.averageValues[index] = result.averageAttributeValue;
        this.calculatedValues[index] = result.calibratedAttributeValue;
      }
    });
  }

  private getCalibrations() {
    this.isLoading = true;
    this.sensorManagementService.getCalibrationData({
      deviceId: this.config.data.deviceId,
      dataType: this.config.data.dataType.toUpperCase(),
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["timestamp", "desc"]}
    }).subscribe(data => {
      this.totalRows = data.totalElements;
      this.dataSource = this.sortCalibrations(data.naSensorCalibrationDTOS);
      this.isLoading = false;
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.rows;
    this.first = event.first;
    this.currentPage = event.first / event.rows;

    this.getCalibrations();
  }

  /**
   * Sort the calibration data by x values
   */

  sortCalibrations(naSensorCalibrationDtos: Array<NaSensorCalibrationDto>): Array<NaSensorCalibrationDto> {
    naSensorCalibrationDtos.forEach(function (value) {
      value.calibrationPoints.sort(function compareFn(firstCalibrationPoint, secondCalibrationPoint) {
        if (firstCalibrationPoint.valueX > secondCalibrationPoint.valueX) {
          return 1;
        } else {
          return -1;
        }
      });
    });
    return naSensorCalibrationDtos;
  }

  protected readonly close = close;

  closeCalibration() {
    this.ref.close();
  }
}
