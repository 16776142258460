<div class="container">
  <h3>{{'hydroponics.manualMeasure.manualMeasure' | translate}}</h3>
  <div class="operative-buttons">
    <div class="start-measure-button">
      <p-button [disabled]="deviceOperationFlow != null"
              (onClick)="createOperationFlow()" label="{{'hydroponics.manualMeasure.startMeasure' | translate}}"></p-button>
    </div>
  </div>
  <div class="result mt-3 mb-3">
    <div *ngIf="deviceOperationFlow">
      <ng-container *ngFor="let step of deviceOperationFlow.deviceOperationFlowSteps; let i = index">
        <div class="mb-3">
          <table class="w-20rem">
            <tr><h6>Measure {{i+1}}</h6></tr>
            <tr>
              <td>Status:</td>
              <td class="base-hover-color">{{step.deviceOperation?.status}}</td>
            </tr>
            <tr>
              <td>Start date:</td>
              <td>{{step.deviceOperation?.operationDate | date: dateService.longdateFormatPattern.toString()}}</td>
            </tr>
            <tr>
              <td>Confirm date:</td>
              <td>{{step.deviceOperation?.confirmationDate | date: dateService.longdateFormatPattern.toString()}}</td>
            </tr>
            <tr *ngIf="step.deviceOperation?.errorCode">
              <td>Error:</td>
              <td>{{step.deviceOperation?.errorCode }} {{ step.deviceOperation?.errorDesc}}</td>
            </tr>
          </table>
        </div>
      </ng-container>
    </div>
    <h4>Results</h4>
    <div *ngIf="measureType == 'BASE'" class="sensor-data xl:font-bold">
      <table>
        <tr>
          <td>Attribute</td>
          <td>Measure 1</td>
          <td>Measure 2</td>
          <td>Measure 3</td>
          <td>Average</td>
          <td>Calculated</td>
        </tr>
        <tr>
          <td>ec</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.ec}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.ec | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.ec | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>ph</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.ph}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.ph | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.ph | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>orp</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.orp}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.orp | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.orp | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>tliquid</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.tliquid}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.tliquid | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.tliquid | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>tamb</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.tamb}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.tamb | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.tamb | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>iodide</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.iodide}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.iodide | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.iodide | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>na</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.na}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.na | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.na | number : '1.0-3'}}</td>
        </tr>
        <tr>
          <td>rh</td>
          <ng-container *ngFor="let data of manualMeasurementData | keyvalue">
            <td>{{data.value?.rh}}</td>
          </ng-container>
          <td>{{averageMeasurementData?.rh | number : '1.0-3'}}</td>
          <td>{{calculatedMeasurementData?.rh | number : '1.0-3'}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="measureType == 'IMPEDANCE'" class="sensor-data xl:font-bold">
      <table>
        <tr>
          <td>{{'hydroponics.manualMeasure.frequency' | translate}}</td>
          <td>{{'hydroponics.manualMeasure.avgImpedance' | translate}}</td>
          <td>{{'hydroponics.manualMeasure.avgImpedancePhase' | translate}}</td>
        </tr>
        <ng-container *ngFor="let data of averageMeasurementDataImpedance?.impedanceSensorDataParams | keyvalue">
          <tr>
            <td>{{data.value?.frequency}}</td>
            <td>{{data.value?.impedance | number : '1.0-2'}}</td>
            <td>{{data.value?.impedancePhase | number : '1.0-2'}}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

  <div>
    <textarea [disabled]="deviceOperationData == null"
              class="w-full mb-2 p-inputtextarea p-inputtext" rows="5" cols="30" pInputTextarea [(ngModel)]="eventText" minlength="1" maxlength="500"></textarea>
  </div>
  <div class="flex-column">
    <p-button [disabled]="deviceOperationData == null" (click)="saveEventToMeasure()" label="{{'hydroponics.manualMeasure.saveComment' | translate}}"></p-button>
    <!--<p-button class="m-1" (click)="closeManualMeasuerement()">{{'hydroponics.common.cancel' | translate}}</p-button>-->
  </div>
</div>
