<div class="card">
  <!--<h2>{{'hydroponics.nutritionDispenser.operations' | translate}}</h2>-->
  <h4>{{'hydroponics.dosage.title' | translate}}</h4>

  <p-button *ngIf="showDeviceOperationParamsForm == false"
            (click)="showDeviceOperationParamsForm = true;"
            label="{{'hydroponics.nutritionDispenser.startNewOperation' | translate}}">
  </p-button>

  <!--<div>
    <label>{{'hydroponics.nutritionDispenser.operationType' | translate}}:</label>
    <p-dropdown appendTo="body" [options]="this.deviceTypeOperationDtos"
                [(ngModel)]="pickedDeviceTypeOperationDto" optionLabel="name"></p-dropdown>
  </div>-->

  <div *ngIf="showDeviceOperationParamsForm == true" class="input-fields">
    <ng-container *ngFor="let deviceOperationParam of deviceOperationParamDto; let i = index">
      <div class="mb-3" *ngIf="deviceOperationParam.deviceTypeOperationParam.type == 'NUMBER'">
        <label>{{'hydroponics.deviceOperation.param.'.concat(deviceOperationParam.actualParam.name) | translate}}: </label>
        <p-inputNumber [(ngModel)]="deviceOperationParam.actualParam.numberValue"></p-inputNumber>
        <span matSuffix *ngIf="deviceOperationParam.unit">{{deviceOperationParam.unit}} &nbsp;</span>
      </div>

      <div class="mb-3" *ngIf="deviceOperationParam.deviceTypeOperationParam.type == 'BOOLEAN'">
        <p-checkbox  [binary]="true"
                     [(ngModel)]="deviceOperationParam.actualParam.booleanValue"
          label="{{'hydroponics.deviceOperation.param.'.concat(deviceOperationParam.actualParam.name) | translate}}"></p-checkbox>
        <span matSuffix *ngIf="deviceOperationParam.unit">{{deviceOperationParam.unit}} &nbsp;</span>
      </div>

      <div class="mb-3" *ngIf="deviceOperationParam.deviceTypeOperationParam.type == 'STRING'">
        <mat-form-field appearance="outline">
          <mat-label>{{'hydroponics.deviceOperation.param.'.concat(deviceOperationParam.actualParam.name) | translate}}</mat-label>
          <input matInput [(ngModel)]="deviceOperationParam.actualParam.stringValue">
          <span matSuffix *ngIf="deviceOperationParam.unit">{{deviceOperationParam.unit}} &nbsp;</span>
        </mat-form-field>
      </div>
    </ng-container>

    <mat-error class="mb-3" *ngIf="this.operationStartValidationError">{{this.operationStartValidationError}}</mat-error>
    <div class="flex justify-content-center gap-2">
      <p-button (click)="cancelOperation()" label="{{ 'hydroponics.common.cancel' | translate}}"></p-button>
      <p-button (click)="startOperation()" label="{{ 'hydroponics.common.save' | translate}}"></p-button>
    </div>
  </div>


  <h4 id="device-parameter-change-history">{{'hydroponics.nutritionDispenser.dispenseHistory' | translate}}</h4>

  <div class="mat-elevation-z8 history-table">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <p-table
       [paginator]="true"
       [rows]="pageSize"
       [lazy]="true"
       [first]="first"
       [totalRecords]="totalRows"
       [rowsPerPageOptions]="pageSizeOptions"
       (onPage)="pageChanged($event)"
       paginatorDropdownAppendTo="body"
      [value]="dataSource">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'hydroponics.nutritionDispenser.operationStartDate' | translate}}</th>
          <th>{{ 'hydroponics.deviceOperation.param.quantity' | translate}}</th>
          <th>{{ 'hydroponics.deviceOperation.param.reverse' | translate}}</th>
          <th>{{ 'hydroponics.nutritionDispenser.responseDate' | translate}}</th>
          <th>{{ 'hydroponics.nutritionDispenser.result' | translate}}</th>
          <th>{{ 'hydroponics.nutritionDispenser.dispensedQuantity' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-element>
        <tr>
          <td>{{element.deviceOperation.operationDate | date: dateService.longdateFormatPattern.toString()}}</td>
          <td>{{getDeviceOperationParamValue('quantity', element.deviceOperation)}}</td>
          <td>{{getDeviceOperationParamValue('reverse', element.deviceOperation)}}</td>
          <td>{{element.deviceOperation.confirmationDate | date: dateService.longdateFormatPattern.toString()}}</td>
          <td class="device-operation-status-{{element.deviceOperation.status}}">{{ deviceOperationUtilService.getDeviceOperationResult(element.deviceOperation)}}</td>
          <td>{{element.dispensedQuantity}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

