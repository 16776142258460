import {Component, OnDestroy, OnInit} from '@angular/core';
import {DateService} from "../../../../util/date.service";
import {
  DeviceOperationControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/device-operation-controller-impl.service";
import {
  DeviceTypeOperationParamDto
} from "../../../../../generated/hydroponics-device-management-api/models/device-type-operation-param-dto";
import {
  DeviceOperationParamDto
} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-param-dto";
import {
  OperateOnDeviceResponse
} from "../../../../../generated/hydroponics-device-management-api/models/operate-on-device-response";
import {interval, Subscription, takeUntil} from "rxjs";
import {
  SensorManagementImplService
} from "../../../../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import {SensorDataDto} from "../../../../../generated/hydroponics-device-management-api/models/sensor-data-dto";
import {
  DeviceOperationDto
} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-dto";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {
  SaveEventDataRequest
} from "../../../../../generated/hydroponics-device-management-api/models/save-event-data-request";
import {
  EventManagerControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {
  CreateDeviceOperationFlowRequest
} from "../../../../../generated/hydroponics-device-management-api/models/create-device-operation-flow-request";
import {
  DeviceOperationFlowControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/device-operation-flow-controller-impl.service";
import {
  DeviceOperationFlowDefinitionDto
} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-flow-definition-dto";
import {
  DeviceOperationFlowDto
} from "../../../../../generated/hydroponics-device-management-api/models/device-operation-flow-dto";
import {MessageService} from "../../../../service/message.service";
import {TranslateService} from "@ngx-translate/core";
import {
  ImpedanceSensorDataParamDto
} from "../../../../../generated/hydroponics-device-management-api/models/impedance-sensor-data-param-dto";
import {
  ImpedanceSensorDataDto
} from "../../../../../generated/hydroponics-device-management-api/models/impedance-sensor-data-dto";
import {
  ImpedanceManagementControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/impedance-management-controller-impl.service";

@Component({
  selector: 'app-manual-measure',
  templateUrl: './sensorboard-manual-measure.component.html',
  styleUrls: ['./sensorboard-manual-measure.component.sass']
})
export class SensorboardManualMeasureComponent implements OnInit, OnDestroy {

  private responsePoll: Subscription;
  private selectedOperationFlowDefinition: DeviceOperationFlowDefinitionDto;
  private pollExistInProgressFlow: Subscription;
  private pollDeviceOperationFlowHistory: Subscription;

  protected measureType: string;
  protected deviceOperationParamDto: Array<{ deviceTypeOperationParam: DeviceTypeOperationParamDto, actualParam: DeviceOperationParamDto }> = [];
  protected response: OperateOnDeviceResponse;
  protected deviceOperationData: DeviceOperationDto;
  protected eventText: string;
  protected existInProgressFlow: boolean;
  protected deviceOperationFlow: DeviceOperationFlowDto;

  protected manualMeasurementData: {[p: number]: SensorDataDto} = {};
  protected calibratedMeasurementData: {[p: number]: SensorDataDto} = {};
  protected averageMeasurementData: SensorDataDto;
  protected calculatedMeasurementData: SensorDataDto;

  protected manualMeasurementDataImpedance: {[p: number]: ImpedanceSensorDataDto} = {};
  protected calibratedMeasurementDataImpedance: {[p: number]: ImpedanceSensorDataDto} = {};
  protected averageMeasurementDataImpedance: ImpedanceSensorDataDto;
  protected calculatedMeasurementDataImpedance: ImpedanceSensorDataDto;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              private deviceOperationService: DeviceOperationControllerImplService,
              private sensorManagementImplService: SensorManagementImplService,
              private impedanceManagementControllerImplService :ImpedanceManagementControllerImplService,
              public dateService: DateService,
              private translate: TranslateService,
              private readonly messageService: MessageService,
              private eventService: EventManagerControllerImplService,
              private deviceOperationFlowController: DeviceOperationFlowControllerImplService) {
  }

  ngOnInit(): void {
    this.getDeviceTypeOperations();
    this.measureType = "BASE";
  }

  createOperationFlow() {
    this.existInProgressFlow = true;
    this.manualMeasurementData = {};
    this.calibratedMeasurementData = {};

    this.manualMeasurementDataImpedance = {};
    this.calibratedMeasurementDataImpedance = {};

    let flowDefinitionCode = this.measureType + "_MANUAL_MEASUREMENT_" + this.config.data.deviceId;
    this.deviceOperationFlowController.getDeviceOperationFlowDefinitionByName({name: flowDefinitionCode}).subscribe(selectedOperationFlowDefinition =>{
      this.selectedOperationFlowDefinition = selectedOperationFlowDefinition;

      this.deviceOperationFlowController.getInProgressStepDefintions({
        deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id
      }).subscribe(value => {
        if (value && value.length > 0){
          this.messageService.warning(this.translate.instant('hydroponics.manualMeasure.runningMeasureMessage'));
          this.getInProgressStepDefinitions();
          this.getDeviceOperationFlowHistory(Number(value));
        } else {
          let createDeviceOperationFlowRequest: CreateDeviceOperationFlowRequest = {
            deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id,
          }

          this.deviceOperationFlowController.createDeviceOperationFlow({
            request: createDeviceOperationFlowRequest
          }).subscribe(flow => {
            this.deviceOperationFlow = flow;
            this.getInProgressStepDefinitions();
            this.getDeviceOperationFlowHistory(this.deviceOperationFlow.id);
          })
        }
      })
    })
  }

  getInProgressStepDefinitions() {
    this.deviceOperationFlowController.getInProgressStepDefintions({deviceOperationFlowDefinitionId: this.selectedOperationFlowDefinition.id}).subscribe(value => {
      if (value && value.length > 0) {
        this.existInProgressFlow = true;
      } else {
        this.existInProgressFlow = false;
      }

      if (this.pollExistInProgressFlow) {
        this.pollExistInProgressFlow.unsubscribe();
      }

      if (this.existInProgressFlow) {
        this.pollExistInProgressFlow = interval(3 * 1000).pipe(takeUntil(interval(300 * 1000))).subscribe(() => {
          if (this.existInProgressFlow) {
            this.getInProgressStepDefinitions();
          } else {
            this.pollExistInProgressFlow.unsubscribe();
          }
        });
      }
    });
  }

  getDeviceOperationFlowHistory(flowId: number) {
    this.deviceOperationFlowController.getDeviceOperationFlow({
      id: flowId,
    }).subscribe(deviceOperationFlow => {
      this.deviceOperationFlow = deviceOperationFlow;

      this.deviceOperationFlow.deviceOperationFlowSteps = this.deviceOperationFlow.deviceOperationFlowSteps.sort((a, b) => a.deviceOperationFlowStepDefinition.stepOrder -
        b.deviceOperationFlowStepDefinition.stepOrder);
      let notScheduledSteps = this.deviceOperationFlow.deviceOperationFlowSteps.filter(deviceOperationFlowStep => deviceOperationFlowStep.status != 'SCHEDULED');
      if (notScheduledSteps && notScheduledSteps.length > 0) {
        if (notScheduledSteps[notScheduledSteps.length - 1] === undefined) {
          console.log("Warn: notScheduledSteps[notScheduledSteps.length - 1] is undefined: " + JSON.stringify(notScheduledSteps));
          console.log("details: " + JSON.stringify(this.deviceOperationFlow.deviceOperationFlowSteps));
        }
        this.deviceOperationFlow['status'] = notScheduledSteps[notScheduledSteps.length - 1].status;
      } else {
        this.deviceOperationFlow['status'] = 'SCHEDULED';
      }

      this.deviceOperationFlow['isCancelFlowEnabledButton'] = this.deviceOperationFlow['status'] == 'IN_PROGRESS' || this.deviceOperationFlow['status'] == 'SCHEDULED';

      this.deviceOperationFlow.deviceOperationFlowSteps.forEach(step =>{
        if (step != null && step.deviceOperation != null){
          this.refreshDeviceOperationAndSensorData(step.deviceOperation.id);
        }
      })

      if (this.pollDeviceOperationFlowHistory) {
        this.pollDeviceOperationFlowHistory.unsubscribe();
      }
      this.pollDeviceOperationFlowHistory = interval(3 * 1000).pipe(takeUntil(interval(300 * 1000))).subscribe(() => {

        const areInProgressFlows = this.deviceOperationFlow['status'] == "SCHEDULED" || this.deviceOperationFlow['status'] == "IN_PROGRESS";
        if (areInProgressFlows) {
          this.getDeviceOperationFlowHistory(this.deviceOperationFlow.id);
        } else {
          this.pollDeviceOperationFlowHistory.unsubscribe();
        }
      });
    });
  }

  calculateAverages(data: { [p: number]: SensorDataDto }, averageData: SensorDataDto) {
    const count = Object.keys(data).length;

    Object.values(data).forEach(sensorData => {
      averageData.ec += sensorData.ec;
      averageData.rh += sensorData.rh;
      averageData.ph += sensorData.ph;
      averageData.na += sensorData.na;
      averageData.tamb += sensorData.tamb;
      averageData.orp += sensorData.orp;
      averageData.iodine += sensorData.iodine;
      averageData.tliquid += sensorData.tliquid;
    });

    averageData.ec /= count;
    averageData.rh /= count;
    averageData.ph /= count;
    averageData.na /= count;
    averageData.tamb /= count;
    averageData.orp /= count;
    averageData.iodine /= count;
    averageData.tliquid /= count;

    return averageData;
  }

  refreshDeviceOperationAndSensorData(deviceOperationId: number) {
    this.deviceOperationService.getDeviceOperation({
      deviceOperationId: deviceOperationId
    }).subscribe(value => {
      this.deviceOperationData = value;
      if (this.deviceOperationData.status == "CONFIRMED") {
        if (this.measureType == "BASE"){
          this.getManualMeasurementData(deviceOperationId);
          this.getCalibratedData(deviceOperationId);
        } else {
          this.getManualMeasurementDataImpedance(deviceOperationId);
          //this.getCalibratedDataImpedance(deviceOperationId);
        }
      }
    });
  }

  getManualMeasurementData(deviceOperationId: number) {
    this.sensorManagementImplService.getManualMeasurementData({
      deviceId: this.config.data.deviceId,
      deviceOperationId: deviceOperationId.toString()
    }).subscribe(value => {
      this.manualMeasurementData[deviceOperationId] = value;

      if (this.deviceOperationFlow['status'] == 'SUCCESS'){
        this.averageMeasurementData = {
          tliquid: 0,
          orp: 0,
          iodine: 0,
          tamb: 0,
          na: 0,
          ec: 0,
          rh: 0,
          ph: 0
        };
        this.averageMeasurementData = this.calculateAverages(this.manualMeasurementData, this.averageMeasurementData);
      }
    });
  }

  getManualMeasurementDataImpedance(deviceOperationId: number) {
    this.impedanceManagementControllerImplService.getManualMeasurementData1({
      deviceId: this.config.data.deviceId,
      deviceOperationId: deviceOperationId.toString()
    }).subscribe(value => {
      this.manualMeasurementDataImpedance[deviceOperationId] = value;

      if (this.deviceOperationFlow['status'] == 'SUCCESS'){
        this.averageMeasurementDataImpedance = {
          // TODO
        };
       this.averageMeasurementDataImpedance = this.calculateAveragesImpedance(this.manualMeasurementDataImpedance, this.averageMeasurementDataImpedance);
      }
    });
  }

  private getCalibratedData(deviceOperationId: number) {
    this.sensorManagementImplService.getCalibratedData({
      deviceId: this.config.data.deviceId,
      deviceOperationId: deviceOperationId.toString()
    }).subscribe(value => {
      this.calibratedMeasurementData[deviceOperationId] = value;

      if (this.deviceOperationFlow['status'] == 'SUCCESS'){
        this.calculatedMeasurementData = {
          tliquid: 0,
          orp: 0,
          iodine: 0,
          tamb: 0,
          na: 0,
          ec: 0,
          rh: 0,
          ph: 0
        };
        this.calculatedMeasurementData = this.calculateAverages(this.calibratedMeasurementData, this.calculatedMeasurementData);
      }
    });
  }

 // private getCalibratedDataImpedance(deviceOperationId: number) {
  //  this.sensorManagementImplService.getCalibratedDataImpedance({
  //    deviceId: this.config.data.deviceId,
  //    deviceOperationId: deviceOperationId.toString()
  //  }).subscribe(value => {
  //    this.calibratedMeasurementDataImpedance[deviceOperationId] = value;

  //    if (this.deviceOperationFlow['status'] == 'SUCCESS'){
  //      this.calculatedMeasurementDataImpedance = {
  //        // TODO
  //      };
   //     this.calculatedMeasurementDataImpedance = this.calculateAveragesImpedance(this.calibratedMeasurementDataImpedance, this.calculatedMeasurementDataImpedance);
//      }
//    });
//  }

  getDeviceTypeOperations() {
    this.deviceOperationService.getDeviceTypeOperations({deviceId: this.config.data.deviceId}).subscribe(value => {
      let deviceTypeOperationDtos = value.deviceTypeOperationDTO;
      let pickedDeviceTypeOperationDto = value.deviceTypeOperationDTO[0];
      if (pickedDeviceTypeOperationDto.deviceTypeOperationParams) {
        this.deviceOperationParamDto = pickedDeviceTypeOperationDto.deviceTypeOperationParams.map((p) => {
          return {
            deviceTypeOperationParam: p,
            actualParam: {
              name: p.name,
            }
          }
        });
      }
    });
  }

  closeManualMeasuerement() {
    if (this.responsePoll && !this.responsePoll.closed) {
      this.responsePoll.unsubscribe();
    }

    if (this.pollExistInProgressFlow) {
      this.pollExistInProgressFlow.unsubscribe();
    }

    if (this.pollDeviceOperationFlowHistory) {
      this.pollDeviceOperationFlowHistory.unsubscribe();
    }

    const resultData = {
      averageAttributeValue : this.averageMeasurementData[this.config.data.dataType.toLowerCase()],
      calibratedAttributeValue : this.calculatedMeasurementData[this.config.data.dataType.toLowerCase()]
    };
    this.ref.close(resultData);
  }

  ngOnDestroy(): void {
    this.closeManualMeasuerement();
    if (this.pollDeviceOperationFlowHistory) {
      this.pollDeviceOperationFlowHistory.unsubscribe();
    }
    if (this.pollExistInProgressFlow) {
      this.pollExistInProgressFlow.unsubscribe();
    }
  }
  saveEventToMeasure() {
    let saveEventDataRequest = {} as SaveEventDataRequest;
    saveEventDataRequest.description = this.eventText;
    saveEventDataRequest.eventTypeName = "Manual measurement";
    saveEventDataRequest.deviceId = this.config.data.deviceId

    this.eventService.saveEvent({body: saveEventDataRequest}).subscribe(value => {
      this.eventText = null;
    });
  }

  private calculateAveragesImpedance(data: { [p: number]: ImpedanceSensorDataDto }, averageData: ImpedanceSensorDataDto) {
    const count = Object.keys(data).length;

    let avgImpedanceSensorDataParams: { [p: number]: ImpedanceSensorDataParamDto } = {};

    Object.values(data).forEach(sensorData => {
      sensorData.impedanceSensorDataParams.forEach(param => {
        if (!avgImpedanceSensorDataParams[param.frequency]) {
          avgImpedanceSensorDataParams[param.frequency] = {
            frequency: param.frequency,
            impedance: 0,
            impedancePhase: 0
          } as ImpedanceSensorDataParamDto;
        }
        avgImpedanceSensorDataParams[param.frequency].impedance += param.impedance;
        avgImpedanceSensorDataParams[param.frequency].impedancePhase += param.impedancePhase;
      });
    });

    Object.values(avgImpedanceSensorDataParams).forEach(param => {
      param.impedance /= count;
      param.impedancePhase /= count;
    });

    averageData.impedanceSensorDataParams = Object.values(avgImpedanceSensorDataParams);
    return averageData;
  }
}
