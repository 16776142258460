/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { WorksheetControllerImplService } from './services/worksheet-controller-impl.service';
import { NutritionDispenserManagementImplService } from './services/nutrition-dispenser-management-impl.service';
import { LocationManagementImplService } from './services/location-management-impl.service';
import { FavouriteChartManagementControllerImplService } from './services/favourite-chart-management-controller-impl.service';
import { EventManagerControllerImplService } from './services/event-manager-controller-impl.service';
import { DeviceManagementImplService } from './services/device-management-impl.service';
import { WorkElementControllerImplService } from './services/work-element-controller-impl.service';
import { SensorManagementImplService } from './services/sensor-management-impl.service';
import { ImpedanceManagementControllerImplService } from './services/impedance-management-controller-impl.service';
import { DeviceOperationControllerImplService } from './services/device-operation-controller-impl.service';
import { DeviceOperationFlowControllerImplService } from './services/device-operation-flow-controller-impl.service';
import { WorkbookControllerImplService } from './services/workbook-controller-impl.service';
import { PlantProfileManagerControllerImplService } from './services/plant-profile-manager-controller-impl.service';
import { AlertManagementImplService } from './services/alert-management-impl.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    WorksheetControllerImplService,
    NutritionDispenserManagementImplService,
    LocationManagementImplService,
    FavouriteChartManagementControllerImplService,
    EventManagerControllerImplService,
    DeviceManagementImplService,
    WorkElementControllerImplService,
    SensorManagementImplService,
    ImpedanceManagementControllerImplService,
    DeviceOperationControllerImplService,
    DeviceOperationFlowControllerImplService,
    WorkbookControllerImplService,
    PlantProfileManagerControllerImplService,
    AlertManagementImplService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
