import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseChartDirective} from 'ng2-charts';
import {
  DeviceManagementImplService,
  LocationManagementImplService
} from 'src/generated/hydroponics-device-management-api/services';
import {DeviceDto, LocationDto} from 'src/generated/hydroponics-device-management-api/models';
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from "@angular/forms";
import {Observable, startWith, Subject, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {DashboardService, ExtDeviceDTO, ExtDeviceGroupDTO} from "./dashboard.service";
import {isEqual, uniqWith} from "lodash-es";
import {
  ChartFilterAttributeChangeEvent,
  ChartFilterDateChangeEvent, ChartFilterFrequenciesChangeEvent,
  ChartFilterLocationChangeEvent
} from "../components/chart-filter/chart-filter.component";
import {DeviceTypeEnum} from "../components/location-tree/location-tree.component";
import {LocationTreeService} from "../components/location-tree/location-tree.service";
import {NodeElement, NodeType} from "../components/locations/locations.component";
import {ChartDevice} from "../components/sensor-measure-dashboard/sensor-measure-dashboard.component";
import {OperationType} from "../components/device/sensorboard/sensor-data/sensor-data.component";


// class ExtDeviceDTO implements DeviceDto {
//   deviceId?: string;
//   deviceMac?: string;
//   deviceType?: DeviceTypeDto;
//   locationReferenceDTO?: LocationReferenceDto;
//   name?: string;
//   searchName?: string;
//   deviceGroup?: ExtDeviceGroupDTO;
// }
//
// class ExtDeviceGroupDTO implements DeviceGroupDto {
//   locationReferenceDTO?: LocationReferenceDto;
//   name?: string;
//   devices?: ExtDeviceDTO[];
//   searchName?: string;
// }


class FilterOption {
  id: string;
  name: string;
  type: 'DEVICE' | 'DEVICE_GROUP';
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {

  private rangeDates: Date[];
  devices: ExtDeviceDTO[] = [];
  deviceGroups: ExtDeviceGroupDTO[] = [];
  // selectedDevice: ExtDeviceDTO = [] as ExtDeviceDTO;
  hierarchy: Array<LocationDto> = [];
  form = new FormControl<string | FilterOption>(new FilterOption());
  filteredOptions: Observable<FilterOption[]>;
  private chartDevices: ChartDevice[] = [];
  private locationIds: any[] = [];
  @ViewChild('inputtag') inputtag: ElementRef;

  chartFilterChangeSubjectSensor: Subject<any> = new Subject<any>();
  chartFilterAttributeChangeSubjectSensor: Subject<string[]> = new Subject<string[]>();
  chartFilterFrequenciesChangeSubjectSensor: Subject<number[]> = new Subject<number[]>();

  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  device: DeviceDto;
  private locationTreeSubscription: Subscription;

  emitChartFilterDateChangeToChild(rangeDates: Date[]) {
    console.log("date changed dashboard next " + rangeDates.toString())
    this.chartFilterChangeSubjectSensor.next(rangeDates);
  }

  constructor(
    private deviceManagementImplService: DeviceManagementImplService,
    private locationManagementImplService: LocationManagementImplService,
    public dialog: MatDialog,
    public dashboardService: DashboardService,
    public locationTreeService: LocationTreeService
  ) {
  }

  async ngOnInit() {
    await this.locationManagementImplService.getHierarchy().subscribe(value => {
      this.hierarchy = value;
      this.getAllDevices();
    });

    this.locationTreeSubscription = this.locationTreeService.isInitialized().subscribe((initialized: boolean) => {
      if (initialized) {
        // ha kész a fa, akkor a rootalapján szűrhetünk elsőre
        this.addDevicesToChart(this.locationTreeService.getRoot());
        this.callChartFilterChangeSubjectSensor();
      }
    });
  }

  callChartFilterChangeSubjectSensor() {
    this.chartFilterChangeSubjectSensor.next({rangeDates: this.rangeDates, chartDevices: this.chartDevices, locationIds: this.locationIds});
  }

  addDevicesToChart(nodeElement: NodeElement) {
    if (nodeElement.nodeType && nodeElement.nodeType == NodeType.DEVICE) {
      this.chartDevices.push({
        deviceId: nodeElement.id,
        settlement: nodeElement.settlement,
        deviceName: nodeElement.deviceName,
        locations: nodeElement.locations,
        alerts: nodeElement.alerts,
        chartAlerts: nodeElement.chartAlerts,
        worksheets: nodeElement.worksheets
      })
    } else if(nodeElement.nodeType && nodeElement.nodeType == NodeType.LOCATION){
      this.locationIds.push(nodeElement.id);
    }

    if (nodeElement.children) {
      nodeElement.children.forEach(child => {
        this.addDevicesToChart(child);
      })
    }
  }

  public _filter(value: string | FilterOption): FilterOption[] {

    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }

    let response: FilterOption[] = [];
    this.deviceGroups.filter(deviceGroup => deviceGroup.searchName.toLowerCase().includes(filterValue))
      .forEach(p => {
        response.push({id: p.name, name: p.name, type: "DEVICE_GROUP"} as FilterOption);
      });

    this.deviceGroups.filter(deviceGroup => deviceGroup.devices.filter(
      option => option.searchName.toLowerCase().includes(filterValue)).forEach(p => {
      response.push({id: deviceGroup.name, name: deviceGroup.name, type: "DEVICE_GROUP"} as FilterOption);
    }));

    let deviceFilterOptions: ExtDeviceDTO[] = this.devices.filter(option => option.searchName.toLowerCase().includes(filterValue)).map(p => p);
    deviceFilterOptions.forEach(device => {
      if (device.deviceGroup == null) {
        response.push({id: device.deviceId, name: device.searchName, type: "DEVICE"} as FilterOption);
      }
    });

    response = uniqWith(response, isEqual);
    // response = uniq(response);

    return response.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }

  public fillDevices() {
    this.filteredOptions = this.form.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.inputtag.nativeElement.value = null;
  }


  private getAllDevices() {
    this.deviceManagementImplService.getAllDevice().subscribe(value => {
      this.devices = value.filter(p => p.deviceType != null).sort((a, b) => a.deviceId.localeCompare(b.deviceId));

      //TODO alap eszközt kiválasztani, pl cookie alapján
      // this.dashboardService.device = value.filter(p => p.deviceId == 'base-sensor-1')[0];
      this.devices.forEach(deviceDTO => {
        const hierarchy = this.getHierarchy(deviceDTO);
        deviceDTO.searchName = deviceDTO.name.concat(" - [" + deviceDTO.deviceId + "]" + (hierarchy ? ' | ' : '') + hierarchy);
      });

      //deviceGroup-ok leválogatása
      this.devices.filter(p => p.deviceGroup != null).map(device => {
        let deviceGroup: ExtDeviceGroupDTO = this.deviceGroups.filter(group => group.name == device.deviceGroup.name)[0];
        if (deviceGroup == null) {
          deviceGroup = device.deviceGroup;
          deviceGroup.devices = [];
          const hierarchy = this.getHierarchy(device);
          deviceGroup.searchName = device.deviceGroup.name.concat((hierarchy ? " | " : "") + hierarchy);
          this.deviceGroups.push(deviceGroup);
        }

        device.deviceGroup = deviceGroup;
        deviceGroup.devices.push(device);
      });

      this.dashboardService.device = this.devices.filter(p => p.deviceGroup == null)[0];
      const device: ExtDeviceDTO = this.dashboardService.device;
      if (!device) {
        this.dashboardService.device = this.devices[0];
        this.form.setValue({
          id: device.deviceGroup.name,
          name: device.deviceGroup.searchName,
          type: "DEVICE_GROUP"
        } as FilterOption);
      } else {
        this.form.setValue({
          id: device.deviceId,
          name: device.searchName,
          type: "DEVICE"
        } as FilterOption);
      }

      this.filteredOptions = this.form.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

    });
  };

  private getHierarchy(device: DeviceDto): string {
    let deviceHierarchy: string = '';

    if (device.deviceGroup == null) {
      if (device.locationReferenceDTO) {
        //Find the root location (first element of the hierarchy).
        let hierarchyLocation = this.hierarchy.filter(value => value.id === device.locationReferenceDTO.root)[0];
        if (hierarchyLocation) {
          deviceHierarchy = hierarchyLocation?.name;
          //while (hierarchyLocation?.id != device.locationReferenceDTO.id) {
          //deviceHierarchy = deviceHierarchy.concat(" - " + hierarchyLocation.child.name);
          //hierarchyLocation = hierarchyLocation.child;
          // }
        }
      }
    } else {
      if (device.deviceGroup.locationReferenceDTO) {
        //Find the root location (first element of the hierarchy).
        let hierarchyLocation = this.hierarchy.filter(value => value.id === device.deviceGroup.locationReferenceDTO.root)[0];
        if (hierarchyLocation) {
          deviceHierarchy = hierarchyLocation?.name;
          //while (hierarchyLocation?.id != device.deviceGroup.locationReferenceDTO.id) {
          //deviceHierarchy = deviceHierarchy.concat(" - " + hierarchyLocation.child.name);
          //hierarchyLocation = hierarchyLocation.child;
          //
        }
      }
    }
    return deviceHierarchy;
  }

  displayFilterOption(option: FilterOption): string {
    if (option) {
      return option.name
    }
    return '';
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;


  changeDevice(selection: FilterOption) {
    if (selection.type == "DEVICE") {
      this.dashboardService.device = this.devices.filter(device => device.deviceId == selection.id)[0];
    } else {
      this.dashboardService.device = this.deviceGroups.filter(group => group.name == selection.id)[0].devices.sort((a, b) => a.deviceId.localeCompare(b.deviceId))[0];
    }
  }

  onAttributeChange(event: ChartFilterAttributeChangeEvent) {
    this.chartFilterAttributeChangeSubjectSensor.next(event.attributeNames);
  }

  onDateChange(event: ChartFilterDateChangeEvent) {
    this.rangeDates = event.rangeDates;
    this.callChartFilterChangeSubjectSensor();
  }

  onLocationChange(event: ChartFilterLocationChangeEvent) {
    this.chartDevices = [];
    this.locationIds = [];

    if (event.selectedNodes){
      event.selectedNodes.forEach((nodeElement, key) => {
        this.addDevicesToChart(nodeElement);
      });
    }

    this.callChartFilterChangeSubjectSensor();
  }

  protected readonly OperationType = OperationType;

  onFrequenciesChange(event: ChartFilterFrequenciesChangeEvent) {
    this.chartFilterFrequenciesChangeSubjectSensor.next(event.frequencies);
  }
}
