import {Component, OnInit} from '@angular/core';
import {
  AlertManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/alert-management-impl.service";
import {
  AlertNotificationDto
} from "../../../../generated/hydroponics-device-management-api/models/alert-notification-dto";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {

  visible: boolean;
  alerts!: AlertNotificationDto[];
  numberOftotalElements: number;

  constructor(private alertService: AlertManagementImplService) {
  }

  ngOnInit(): void {
    this.visible = false;

    this.getAlerts();
  }

  showDialog() {
    this.visible = true;
  }

  tableClosed() {
    this.visible = false;
  }

  getAlerts() {
    this.alertService.getUserAlerts({"X-Authenticated-User": null, pageable: {page: 0, size: 1}}).subscribe(value => {
      this.numberOftotalElements = value.totalElements;
      this.alerts = value.content;
    })
  }
}
