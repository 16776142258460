import {Component, Input, OnInit} from '@angular/core';
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  SaveWorksheetCommentRequestDto
} from "../../../generated/hydroponics-device-management-api/models/save-worksheet-comment-request-dto";
import {WorksheetCommentDto} from "../../../generated/hydroponics-device-management-api/models/worksheet-comment-dto";
import {WorkTypeEnum} from "../worksheet-create/worksheet-create.component";
import {WorkElementDto} from "../../../generated/hydroponics-device-management-api/models/work-element-dto";
import {DateService} from "../../util/date.service";
import {LoadWorksheetsService} from "../../service/load-worksheets.service";

@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.sass']
})
export class WorksheetComponent implements OnInit {
  @Input() workElement: WorkElementDto;
  @Input() isExtended: boolean = false;
  inProcess: boolean = false;
  comment: string;
  closeWorksheetOnSave: boolean = false;
  comments: Array<WorksheetCommentDto>;

  protected readonly WorkTypeEnum = WorkTypeEnum;

  constructor(public worksheetControllerImplService: WorksheetControllerImplService,
              public dateService: DateService,
              private loadWorksheetsService: LoadWorksheetsService) { }

  ngOnInit(): void {
    if (this.workElement.type == WorkTypeEnum.WORKSHEET && this.isExtended){
      this.getComments();
    }
  }

  worksheetInProcess(){
    this.inProcess = true;
    this.comment = null;
  }

  saveComment(){
    let request : SaveWorksheetCommentRequestDto = {};
    request.comment = this.comment;
    request.worksheetId = this.workElement.worksheetDTO.id;
    this.worksheetControllerImplService.createWorksheetComment({body: request}).subscribe(next => {
      if (this.closeWorksheetOnSave && this.closeWorksheetOnSave == true){
        this.worksheetControllerImplService.closeWorksheet({worksheetId: request.worksheetId}).subscribe(next => {
          this.inProcess = false;
        })
      } else {
        this.inProcess = false;
      }
        this.loadWorksheetsService.emitEvent();
    });

  }

  getComments(){
    this.worksheetControllerImplService.getWorksheetComments({worksheetId: this.workElement.worksheetDTO.id}).subscribe(comments =>{
      this.comments = comments;
    })
  }

  getId(workElementDto: WorkElementDto): any {
    if (workElementDto.type == WorkTypeEnum.EVENT){
      return workElementDto.eventDTO.device ? workElementDto.eventDTO.device.deviceId : workElementDto.eventDTO.location ? workElementDto.eventDTO.location.id : null;
    } else {
      return workElementDto.worksheetDTO.device ? workElementDto.worksheetDTO.device.deviceId : workElementDto.worksheetDTO.location ? workElementDto.worksheetDTO.location.id : null;
    }
  }

  getType(workElementDto: WorkElementDto) {
    let device = null;
    let location = null;

    if (workElementDto.type == WorkTypeEnum.EVENT){
      device = workElementDto.eventDTO.device;
      location = workElementDto.eventDTO.location;
    } else {
      device = workElementDto.worksheetDTO.device;
      location = workElementDto.worksheetDTO.location;
    }

    return device != null ? 'DEVICE' : location != null ? 'LOCATION' : null
  }
}
