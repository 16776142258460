import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceTypeEnum} from "../location-tree/location-tree.component";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";
import {ChartFilterLocationChangeEvent} from "../chart-filter/chart-filter.component";
import {NodeElement, NodeType} from "../locations/locations.component";
import {WorkElementDto} from "../../../generated/hydroponics-device-management-api/models/work-element-dto";
import {
  WorkElementRequestDto
} from "../../../generated/hydroponics-device-management-api/models/work-element-request-dto";
import {
  WorkElementControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/work-element-controller-impl.service";
import {Subject} from "rxjs";
import {ChartFilterService} from "../chart-filter/chart-filter.service";
import {LoadWorksheetsService} from "../../service/load-worksheets.service";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

export interface ChartFilterDateChangeEvent {
  rangeDates: Date[];
}

@Component({
  selector: 'app-worksheet-dashboard',
  templateUrl: './worksheet-dashboard.component.html',
  styleUrls: ['./worksheet-dashboard.component.sass']
})
export class WorksheetDashboardComponent implements OnInit, OnDestroy{
  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  private deviceIds: string[] = [];
  private locationIds: any[] = [];
  protected workElements: WorkElementDto[] = [];
  private type: string;
  totalRows = 0;
  pageSize = 10;
  first = 0;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  private rangeDates: Date[];
  chartFilterChangeSubjectSensor: Subject<any> = new Subject<any>();

  constructor(private route: ActivatedRoute,
              public workElementControllerImplService: WorkElementControllerImplService,
              public chartFilterService: ChartFilterService,
              private loadWorksheetsService: LoadWorksheetsService) { }

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type') || '';

    if (this.type == 'LOCATION'){
      this.locationIds.push(this.route.snapshot.paramMap.get('id') || '');
    } else if (this.type == 'DEVICE'){
      this.deviceIds.push(this.route.snapshot.paramMap.get('id') || '');
    }
    this.chartFilterService.markInitialized();

   // this.callChartFilterChangeSubjectSensor();

    this.loadWorksheetsService.event$.subscribe(value => {
      this.loadData(this.rangeDates[0], null);
    })
  }

  addDevicesToChart(nodeElement : NodeElement){
    if (nodeElement.nodeType && nodeElement.nodeType == NodeType.DEVICE){
      this.deviceIds.push(nodeElement.id)
    } else if(nodeElement.nodeType && nodeElement.nodeType == NodeType.LOCATION){
      this.locationIds.push(nodeElement.id);
    }

    if (nodeElement.children){
      nodeElement.children.forEach(child =>{
        this.addDevicesToChart(child);
      })
    }
  }

  locationChanged(event: ChartFilterLocationChangeEvent) {
    this.deviceIds = [];
    this.locationIds = [];
    if (event.selectedNodes){
      event.selectedNodes.forEach((nodeElement, key) => {
        this.addDevicesToChart(nodeElement);
      });
      this.loadData(this.rangeDates[0], this.rangeDates[1]);
    }
  }

  private loadData(startDate: Date, endDate: Date) {
    this.workElements = [];

    let request :WorkElementRequestDto = {
      startDate: moment(startDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
      endDate: endDate == null ? null : moment(endDate).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSS'),
      deviceIds: this.deviceIds,
      locationIds: this.locationIds
    }
    this.workElementControllerImplService.getWorkElemets({
      body: request,
      pageable: {page: this.currentPage, size: this.pageSize}
    }).subscribe(workElements =>{
      this.totalRows = workElements.totalElements;
      this.workElements = workElements.content;
    })
  }

  ngOnDestroy(): void {
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.rows;
    this.first = event.first;
    this.currentPage = event.page

    this.loadData(this.rangeDates[0], this.rangeDates[1]);
  }

  onDateChange(event: ChartFilterDateChangeEvent) {
    this.rangeDates = event.rangeDates;
    this.callChartFilterChangeSubjectSensor();
  }

  callChartFilterChangeSubjectSensor(){
    this.chartFilterChangeSubjectSensor.next({rangeDates: this.rangeDates, locationIds: this.locationIds});
    this.loadData(this.rangeDates[0], this.rangeDates[1]);
  }
}
