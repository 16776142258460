import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceTypeEnum} from "../location-tree/location-tree.component";
import {
  ChartFilterAttributeChangeEvent,
  ChartFilterDateChangeEvent, ChartFilterFrequenciesChangeEvent,
  ChartFilterLocationChangeEvent
} from "../chart-filter/chart-filter.component";
import {Subject, Subscription} from "rxjs";
import {LocationTreeService} from "../location-tree/location-tree.service";
import {NodeElement, NodeType} from "../locations/locations.component";
import {ChartDevice} from "../sensor-measure-dashboard/sensor-measure-dashboard.component";
import {OperationType} from "../device/sensorboard/sensor-data/sensor-data.component";

@Component({
  selector: 'app-calibration-board',
  templateUrl: './calibration-board.component.html',
  styleUrls: ['./calibration-board.component.sass']
})
export class CalibrationBoardComponent implements OnInit, OnDestroy {
  private chartDevices: ChartDevice[] = [];
  private locationIds: any[] = [];
  get locationTreeSubscription(): Subscription {
    return this._locationTreeSubscription;
  }

  set locationTreeSubscription(value: Subscription) {
    this._locationTreeSubscription = value;
  }
  protected readonly OperationType = OperationType;
  protected readonly DeviceTypeEnum = DeviceTypeEnum;
  private rangeDates: Date[];
  chartFilterChangeSubjectSensor: Subject<any> = new Subject<any>();
  chartFilterAttributeChangeSubjectSensor: Subject<string[]> = new Subject<string[]>();
  chartFilterFrequenciesChangeSubjectSensor: Subject<number[]> = new Subject<number[]>();
  private _locationTreeSubscription: Subscription;


  constructor(public locationTreeService: LocationTreeService) { }

  ngOnInit(): void {
    this._locationTreeSubscription = this.locationTreeService.isInitialized().subscribe((initialized: boolean) => {
      if (initialized){
        // ha kész a fa, akkor a rootalapján szűrhetünk elsőre
        this.addDevicesToChart(this.locationTreeService.getRoot());
        this.callChartFilterChangeSubjectSensor();
      }
    });
  }

  addDevicesToChart(nodeElement : NodeElement){
    if (nodeElement.nodeType && nodeElement.nodeType == NodeType.DEVICE){
      this.chartDevices.push({
        deviceId: nodeElement.id,
        settlement: nodeElement.settlement,
        deviceName: nodeElement.deviceName,
        locations: nodeElement.locations,
        alerts: nodeElement.alerts,
        chartAlerts: nodeElement.chartAlerts,
        worksheets: nodeElement.worksheets
      })
    } else if(nodeElement.nodeType && nodeElement.nodeType == NodeType.LOCATION){
      this.locationIds.push(nodeElement.id);
    }

    if (nodeElement.children){
      nodeElement.children.forEach(child =>{
        this.addDevicesToChart(child);
      })
    }
  }

  locationChanged(event: ChartFilterLocationChangeEvent) {
    this.chartDevices = [];
    this.locationIds = [];

    if (event.selectedNodes){
      event.selectedNodes.forEach((nodeElement, key) => {
        this.addDevicesToChart(nodeElement);
      });
    }

    this.callChartFilterChangeSubjectSensor();
  }

  onDateChange(event: ChartFilterDateChangeEvent) {
    this.rangeDates = event.rangeDates;
    this.callChartFilterChangeSubjectSensor();
  }

  callChartFilterChangeSubjectSensor(){
    this.chartFilterChangeSubjectSensor.next({rangeDates: this.rangeDates, chartDevices: this.chartDevices, locationIds: this.locationIds});
  }

  onAttributeChange(event: ChartFilterAttributeChangeEvent) {
    this.chartFilterAttributeChangeSubjectSensor.next(event.attributeNames);
  }

  ngOnDestroy(): void {
    if (this.locationTreeSubscription){
      this.locationTreeSubscription.unsubscribe();
    }
  }

  onFrequenciesChange(event: ChartFilterFrequenciesChangeEvent) {
    this.chartFilterFrequenciesChangeSubjectSensor.next(event.frequencies);
  }
}
