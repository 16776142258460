import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AlertNotificationDto
} from "../../../../generated/hydroponics-device-management-api/models/alert-notification-dto";
import {
  AlertManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/alert-management-impl.service";
import {SensorDataType} from "../../device/sensorboard/sensor-data/sensor-data.component";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.sass']
})
export class AlertTableComponent implements OnInit {

  @Input() deviceId: string;
  @Input() dataType: string;
  @Input() visible!: boolean;
  @Output() closeTable = new EventEmitter<void>();
  @Output() onHideAlert = new EventEmitter<number>();

  alerts!: AlertNotificationDto[];
  pageSizeOptions: number[] = [5, 10, 15, 30, 50, 100];
  isloading: boolean;
  totalRecords = 0;
  rows = 5;
  first = 0;
  currentPage = 0;

  constructor(private alertManagementImplService: AlertManagementImplService) {
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  private getAlerts() {
    this.isloading = true;
    if (this.deviceId != undefined && this.deviceId != null){
      this.alertManagementImplService.getUserAlertsByDevice({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows},
        deviceId: this.deviceId,
        dataType: this.dataType
      }).subscribe(value => {
        this.alerts = value.content;
        this.totalRecords = value.totalElements;
        this.isloading = false;
      });
    } else {
      this.alertManagementImplService.getUserAlerts({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows}
      }).subscribe(value => {
        this.alerts = value.content;
        this.totalRecords = value.totalElements;
        this.isloading = false;
      });
    }
  }

  actionOnClose() {
    this.closeTable.emit();
  }

  hideAlert(alertNotificationId: number) {
    this.alertManagementImplService.hideAlert({alertNotificationId}).subscribe(value => {
      this.onHideAlert.emit(alertNotificationId);
      this.getAlerts();
    });
  }

  pageChanged(event: PageEvent) {
    this.rows = event.rows;
    this.first = event.first;
    this.currentPage = event.first / event.rows;

    this.getAlerts();
  }
}
