<div class="card">
  <p-button
    icon="pi pi-plus"
    label="{{'hydroponics.worksheet.createNewButton' | translate}}"
    (onClick)="onCreate()">
  </p-button>
</div>

<p-dialog modal="true" [(visible)]="dialogVisible" (onHide)="onClose()">
  <div class="mt-3 flex-column">
    <div class="mb-3 flex align-items-center">
      <div class="w-7rem">
        <label>{{'hydroponics.worksheet.createType' | translate}}:</label>
      </div>
      <div class="w-full">
        <p-selectButton
          [options]="creationTypes"
          [(ngModel)]="creationType"
          optionLabel="label"
          optionValue="value">
        </p-selectButton>
      </div>
    </div>

    <div class="mb-3 flex align-items-center">
      <div class="w-7rem">
        <label>{{'hydroponics.worksheet.createDescription' | translate}}:</label>
      </div>
      <div class="w-full">
        <textarea class="p-inputtextarea p-inputtext w-full" rows="5" cols="30" pInputTextarea [(ngModel)]="description"></textarea>
      </div>
    </div>

    <div class="mb-3 flex align-items-center">
      <div class="w-7rem">
        <label>{{'hydroponics.worksheet.createDevice' | translate}}:</label>
      </div>
      <div class="w-full">
        <input pInputText class="p-inputtext w-full"
               (click)="deviceDialogVisible = true"
               placeholder="{{'hydroponics.button.choose' | translate}}"
               [value]="deviceName ? deviceName : locationName" />
      </div>
    </div>

    <div class="mb-3 flex align-items-center">
      <p-button label="{{'hydroponics.button.save' | translate}}" [disabled] = "!isFormValid()" (onClick)="onSave()" class="mr-3"></p-button>
      <p-button label="{{'hydroponics.button.cancel' | translate}}" (onClick)="onClose()"></p-button>
    </div>
  </div>
</p-dialog>


<p-dialog modal="true" [(visible)]="deviceDialogVisible">
  <div class="mb-3">
    <app-location-tree [extendedTree]="false"
                       [isMultiple]="false"
                       [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
                       [isOnlyDeviceSelectable]="false"
                       (onLocationChange)="locationTreeLocationChanged($event)">
    </app-location-tree>
  </div>
  <div class="float-right">
    <p-button (onClick)="deviceDialogVisible = false" label="{{'hydroponics.button.choose' | translate}}"></p-button>
  </div>
</p-dialog>
