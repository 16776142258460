import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SensorDataType} from "../device/sensorboard/sensor-data/sensor-data.component";
import {DeviceTypeEnum, LocationTreeLocationChangeEvent} from "../location-tree/location-tree.component";
import {NodeElement} from "../locations/locations.component";
import {ChartFilterService} from "./chart-filter.service";
import {Subscription} from "rxjs";
import {
  ImpedanceManagementControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/impedance-management-controller-impl.service";
import {ImpedanceSensorComponent} from "../device/impedance/impedance-sensor/impedance-sensor.component";
import {FormControl} from "@angular/forms";
import {CookieService} from "ngx-cookie-service";

export interface ChartFilterDateChangeEvent {
  rangeDates: Date[];
}

export interface ChartFilterAttributeChangeEvent {
  attributeNames: any[];
}

export interface ChartFilterLocationChangeEvent {
  selectedNodes: NodeElement[];
  selectedNode: NodeElement;
}

export interface ChartFilterFrequenciesChangeEvent {
  frequencies: number[];
}

@Component({
  selector: 'app-chart-filter',
  templateUrl: './chart-filter.component.html',
  styleUrls: ['./chart-filter.component.sass']
})
export class ChartFilterComponent implements OnInit, OnDestroy {
  private static readonly COOKIE_PICKED_FREQUENCES = 'picked-frequences';

  attributeNames: any[] = [
    { name: SensorDataType.EC, value: SensorDataType.EC},
    { name: SensorDataType.RH, value: SensorDataType.RH},
    { name: SensorDataType.ORP, value: SensorDataType.ORP},
    { name: SensorDataType.TAMB, value: SensorDataType.TAMB},
    { name: SensorDataType.IODIDE, value: SensorDataType.IODIDE},
    { name: SensorDataType.TLIQUID, value: SensorDataType.TLIQUID},
    { name: SensorDataType.NA, value: SensorDataType.NA},
    { name: SensorDataType.PH, value: SensorDataType.PH},
    { name: SensorDataType.IMPEDANCE, value: SensorDataType.IMPEDANCE},
    { name: SensorDataType.IMPEDANCE_PHASE, value: SensorDataType.IMPEDANCE_PHASE},
  ];

  selectedAttributeNames: any[] = [];
  rangeDates: Date[] = [];
  selectedNodes: NodeElement[];
  selectedNode: NodeElement;
  dialogVisible: boolean;

  frequenciesList: number[] = [];
  pickedFrequencies: number[] = [];

  chartFilterSubscription: Subscription;

  @Input() visible: boolean = true;
  @Input() isMultiple: boolean = true;
  @Input() isDateFilter: boolean = true;
  @Input() isLocationFilter: boolean = true;
  @Input() isAttributeFilter: boolean = true;
  @Input() isFrequencyFilter: boolean = true;
  @Input() isOnlyDeviceSelectable: boolean = false;
  @Input() deviceTypes: DeviceTypeEnum[] = [];

  @Output() onDateChange: EventEmitter<ChartFilterDateChangeEvent> = new EventEmitter();
  @Output() onAttributeChange: EventEmitter<ChartFilterAttributeChangeEvent> = new EventEmitter();
  @Output() onLocationChange: EventEmitter<ChartFilterLocationChangeEvent> = new EventEmitter();
  @Output() onFrequenciesChange: EventEmitter<ChartFilterFrequenciesChangeEvent> = new EventEmitter();

  constructor(
    private chartFilterService: ChartFilterService,
    private impedanceManagerControllerImplService: ImpedanceManagementControllerImplService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.chartFilterSubscription = this.chartFilterService.isInitialized().subscribe((initialized: boolean) => {
      if (initialized){
        // utolsó 72 óra van szűrve
        const end = new Date();
        const start = new Date(end.getTime());
        start.setHours(end.getHours() - 72);
        this.rangeDates.push(start);
        this.rangeDates.push(end);
        this.emitDateChange();

        // Kezdetben minden attribútum kijelölve
        this.selectedAttributeNames = Object.values(SensorDataType);
        this.emitAttributeChange();

        this.getFrequencies();
      }
    });
  }

  public getFrequencies() {
    this.impedanceManagerControllerImplService.getFrequencies().subscribe(frequencies => {
      frequencies.forEach(frequency => this.frequenciesList.push(Number(frequency)));
      let cookiePickedFrequences = this.cookieService.get(ChartFilterComponent.COOKIE_PICKED_FREQUENCES);
      if (!!cookiePickedFrequences) {
        this.pickedFrequencies = JSON.parse(cookiePickedFrequences);
      }
      this.emitFrequenciesChanged();
    });
  }

  saveSelectedFrequences() {
    this.cookieService.set(ChartFilterComponent.COOKIE_PICKED_FREQUENCES, JSON.stringify(this.pickedFrequencies));
  }

  public frequenceSelectionChanged() {
    //this.loadImpedanceSensorData();
    this.saveSelectedFrequences();
    this.emitFrequenciesChanged();
  }

  private emitFrequenciesChanged() {
    this.onFrequenciesChange.emit({
      frequencies: this.pickedFrequencies
    });
  }

  emitDateChange() {
    this.onDateChange.emit({
      rangeDates: this.rangeDates,
    });
  }

  emitAttributeChange() {
    this.onAttributeChange.emit({
      attributeNames: this.selectedAttributeNames,
    });
  }

  emitLocationChange() {
    this.onLocationChange.emit({
      selectedNodes: this.selectedNodes,
      selectedNode: this.selectedNode
    });
  }

  locationTreeLocationChanged(event: LocationTreeLocationChangeEvent) {
    this.selectedNodes = event.selectedNodes;
    this.selectedNode = event.selectedNode;
  }

  showLocationFilterDialog() {
    this.dialogVisible = true;
  }

  ngOnDestroy(): void {
    if (this.chartFilterSubscription) {
      this.chartFilterSubscription.unsubscribe();
    }
  }

  get selectedNodesLabels(): string {
    return this.selectedNodes.map(node => node.label).join(', ');
  }
}
