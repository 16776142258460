<app-chart-filter
  (onDateChange)="onDateChange($event)"
  [isAttributeFilter]="false"
  [deviceTypes]="[DeviceTypeEnum.SENSORBOARD]"
  [isOnlyDeviceSelectable]="false"
  [isFrequencyFilter]="false"
  (onLocationChange)="locationChanged($event)">
</app-chart-filter>

<app-worksheet-create></app-worksheet-create>

<ng-container *ngFor="let workElement of workElements">
  <app-worksheet
    [isExtended]="true"
    [workElement]="workElement">
  </app-worksheet>
</ng-container>

<p-paginator
  class="paginator-component"
  (onPageChange)="pageChanged($event)"
  [first]="first"
  [rows]="pageSize"
  [totalRecords]="totalRows"
  [rowsPerPageOptions]="pageSizeOptions"></p-paginator>
