<div class="container">
  <h2>{{'hydroponics.manualMeasure.manualMeasure' | translate}}</h2>
  <div class="operative-buttons">
    <div class="start-measure-button">
      <button mat-raised-button color="primary" [disabled]="deviceOperationData"
              (click)="startMeasure()">{{'hydroponics.manualMeasure.startMeasure' | translate}}</button>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div class="result ">
    <div class="card">
      <div class="card-title">Device operation data</div>
      <table>
        <tr>
          <td>Device operation status:</td>
          <td>{{deviceOperationData?.status}}</td>
        </tr>
        <tr>
          <td>Device operation start date:</td>
          <td>{{deviceOperationData?.operationDate}}</td>
        </tr>
        <tr>
          <td>Device operation confirm date:</td>
          <td>{{deviceOperationData?.confirmationDate}}</td>
        </tr>
        <tr *ngIf="deviceOperationData?.errorCode">
          <td>Device operation error:</td>
          <td>{{deviceOperationData?.errorCode }} {{ deviceOperationData?.errorDesc}}</td>
        </tr>
      </table>
    </div>
    <mat-divider class="divider"></mat-divider>

    <div class="sensor-data card">
      <div class="card-title">Sensor data</div>
        <table>
          <tr>
            <td>timestamp:</td>
            <td>{{manualMeasurementData?.timestamp }}</td>
          </tr>
          <tr>
            <td>data:</td>
            <td>{{manualMeasurementData?.impedanceSensorDataParams | json }}</td>
          </tr>
        </table>
    </div>
  </div>

  <div>
    <div>
      <button mat-raised-button mat-dialog-close="true" (click)="closeManualMeasuerement()"
              color="warn">{{'hydroponics.common.cancel' | translate}}</button>
    </div>
  </div>
</div>
