import {Component, OnInit} from '@angular/core';
import {
  AuthorizedUserManagementControllerImplService
} from "../../../generated/hydroponics-user-management-api/services/authorized-user-management-controller-impl.service";
import {
  LocationManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/location-management-impl.service";
import {LocationDto} from "../../../generated/hydroponics-device-management-api/models/location-dto";
import {
  LocationStatusRequest
} from "../../../generated/hydroponics-device-management-api/models/location-status-request";
import {DeviceStatusRequest} from "../../../generated/hydroponics-device-management-api/models/device-status-request";
import {DeviceToLocationDto} from "../../../generated/hydroponics-device-management-api/models/device-to-location-dto";
import {OrganizationTreeDto} from "../../../generated/hydroponics-device-management-api/models/organization-tree-dto";
import {DeviceAdditionDto} from "../../../generated/hydroponics-device-management-api/models/device-addition-dto";
import {
  DeviceManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/device-management-impl.service";
import {ContextMenu} from "primeng/contextmenu";
import {TranslateService} from "@ngx-translate/core";
import {
  PlantProfileManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/plant-profile-manager-controller-impl.service";
import {PlantProfileDto} from "../../../generated/hydroponics-device-management-api/models/plant-profile-dto";
import {MenuItem} from "primeng/api";
import {
  EventManagerControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/event-manager-controller-impl.service";
import {
  SaveEventDataRequest
} from "../../../generated/hydroponics-device-management-api/models/save-event-data-request";
import {
  PlantProfileSaveRequest
} from "../../../generated/hydroponics-device-management-api/models/plant-profile-save-request";
import {
  PlantProfileSaveEvent,
  ProfileType
} from "../plant-profile-attribute-manager/plant-profile-attribute-manager.component";
import {Subject} from "rxjs";
import {DeviceTypeEnum} from "../location-tree/location-tree.component";
import {Router} from "@angular/router";
import {SENSORS_ROUTE, WORKSHEET} from "../../app-routing.module";
import {LocationTreeService} from "../location-tree/location-tree.service";
import {
  DeviceAlertsCountDto
} from "../../../generated/hydroponics-device-management-api/models/device-alerts-count-dto";

const PAUSED = "PAUSED";
const ACTIVE = "ACTIVE";

export enum NodeRoleType {
  ROOT="ROOT", MIDDLE="MIDDLE", LEAF="LEAF"
}

export enum NodeType {
  ORGANIZATION = "ORGANIZATION",
  ESTABLISHMENT = "ESTABLISHMENT",
  LOCATION = "LOCATION",
  DEVICE = "DEVICE"
}

export interface NodeElement {
  id: any;
  nodeType: NodeType;
  label: string;
  paused: boolean;
  expanded?: boolean;
  selectable?: boolean;
  children?: NodeElement[];
  worksheets: number;
  alerts: number;
  chartAlerts: DeviceAlertsCountDto;
  type: NodeRoleType;
  plantProfile?: PlantProfileDto;
  defaultProfile?: boolean;
  profileEditable?: boolean;
  settlement?: string;
  deviceName?: string;
  locations?: string;
}
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.sass']
})
export class LocationsComponent implements OnInit {
  protected readonly ProfileType = ProfileType;

  tree: NodeElement[];
  contextMenuItems: MenuItem[];
  selectedNode: NodeElement;
  eventText: string;
  dialogVisible: boolean;
  measureDialogVisible: boolean;
  confirmMessage: string;
  deviceTypes: DeviceTypeEnum[] = [DeviceTypeEnum.SENSORBOARD];

  loadPlantProfileSubject:Subject<void> = new Subject();

  constructor(private readonly userManagementService: AuthorizedUserManagementControllerImplService,
              private readonly locationManagementImplService: LocationManagementImplService,
              private readonly deviceManagementImplService: DeviceManagementImplService,
              public translate: TranslateService,
              private locationTreeService: LocationTreeService,
              private eventService: EventManagerControllerImplService,
              private plantProfileService: PlantProfileManagerControllerImplService, private router: Router) { }

  ngOnInit(): void {
    this.locationManagementImplService.getOrganizationTreeWithStatuses().subscribe(organizationTree => {
      this.buildTree(organizationTree);
    });

    this.eventText = undefined;
    this.measureDialogVisible = false;
    this.dialogVisible = false;
  }

  showContextMenu(node: NodeElement, contextMenu: ContextMenu, event: MouseEvent) {
    this.selectedNode = node;
    this.contextMenuItems = [
      {label: this.translate.instant('hydroponics.location.jumpToErrors'), icon: 'pi pi-reply', command: (event) => this.jumpToErrors()},
      {label: this.translate.instant('hydroponics.location.jumpToWorksheets'), icon: 'pi pi-reply', command: (event) => this.jumpToWS()},
      {label: this.translate.instant('hydroponics.location.startMeasure'), icon: 'pi pi-play' , command: (event) => this.changeStatus(), visible: this.selectedNode && this.selectedNode.paused },
      {label: this.translate.instant('hydroponics.location.stopMeasure'), icon: 'pi pi-pause', command: (event) => this.changeStatus(), visible: this.selectedNode && !this.selectedNode.paused},
      {label: this.translate.instant('hydroponics.location.setPlantProfile'), icon: 'pi pi-sun', command: (event) => this.loadPlantProfile(), visible: this.selectedNode && this.selectedNode.profileEditable},
      {label: this.translate.instant('hydroponics.location.viewPlantProfile'), icon: 'pi pi-eye', command: (event) => this.loadPlantProfile(), visible: this.selectedNode && !this.selectedNode.profileEditable}
    ];
    contextMenu.show(event);
    event.stopPropagation();
  }

  private buildTree(organizationTree: OrganizationTreeDto) {
    let establishments: NodeElement[] = [];
    let orgSumValues: { alerts: number, worksheets: number } = { alerts: 0 , worksheets: 0};

    for (let establishment of organizationTree.organization.establishments) {
      let locations: NodeElement[] = [];

      for (let location of establishment.locations) {
        let child = this.buildLocationDevicesRecursively(location, organizationTree.deviceAdditions, true, establishment.city, "");
        locations.push(child);
      }

      let establishmentSumValues: { alerts: number, worksheets: number } = { alerts: 0 , worksheets: 0};
      locations.forEach(location =>{
        establishmentSumValues.alerts += location.alerts;
        establishmentSumValues.worksheets += location.worksheets;
      })

      establishments.push({
        id: establishment.city,
        nodeType: NodeType.ESTABLISHMENT,
        label: establishment.city,
        paused: false,
        expanded: locations && locations.length > 0,
        chartAlerts: null,
        children: locations,
        worksheets: establishmentSumValues.worksheets,
        alerts: establishmentSumValues.alerts,
        type: NodeRoleType.MIDDLE,
      })

      orgSumValues.worksheets += establishmentSumValues.worksheets;
      orgSumValues.alerts += establishmentSumValues.alerts;
    }

    this.tree = [{
        id: organizationTree.organization.id,
        nodeType: NodeType.ORGANIZATION,
        label: organizationTree.organization.name,
        paused: false,
        expanded: true,
        children: establishments,
        worksheets: orgSumValues.worksheets,
        alerts: orgSumValues.alerts,
      chartAlerts: null,
        type: NodeRoleType.ROOT
     }];
  }

  private buildLocationDevicesRecursively(location: LocationDto, deviceAdditions: {[p: string]: DeviceAdditionDto}, profileEditable, settlementLabel: string, locationsLabel: string){
    let locationNode: NodeElement = {
      id: location.id,
      nodeType: NodeType.LOCATION,
      label: location.name,
      paused: location.status == PAUSED,
      worksheets: 0,
      alerts: 0,
      chartAlerts: null,
      type: NodeRoleType.LEAF,
      plantProfile: location.plantProfile ? location.plantProfile : undefined,
      defaultProfile: true,
      profileEditable: profileEditable
    };

    if (location.children || location.deviceToLocations){
      let childrenNodes: NodeElement[] = [];
      let nodeSumValues: { alerts: number, worksheets: number } = { alerts: 0 , worksheets: 0};

      if (location.children) {
        for (let child of location.children) {
          let labelToLocation = locationsLabel + " - " + location.name
          let childrenNode = this.buildLocationDevicesRecursively(child, deviceAdditions, location.plantProfile == null, settlementLabel, labelToLocation);
          childrenNodes.push(childrenNode)
          nodeSumValues.alerts += childrenNode.alerts;
          nodeSumValues.worksheets += childrenNode.worksheets;
        }
      }

      if (location.deviceToLocations && location.deviceToLocations.length > 0) {
        let locationsLabelToDevice = locationsLabel + " - " + location.name
        this.buildDeviceLeaves(location.deviceToLocations, childrenNodes, nodeSumValues, deviceAdditions, location.plantProfile == null, locationsLabelToDevice,
          settlementLabel);
      }

      locationNode.worksheets = nodeSumValues.worksheets;
      locationNode.alerts = nodeSumValues.alerts;

      if (childrenNodes.length > 0){
        locationNode.children = childrenNodes;
        locationNode.expanded = true;
        locationNode.type = NodeRoleType.MIDDLE;
      }
    }

    return locationNode;
  }

  changeStatus(): void {
    this.measureDialogVisible = true;
    if (this.selectedNode.paused == false){
      this.confirmMessage = this.translate.instant('hydroponics.location.stopMeasureQuestion');
    } else if(this.selectedNode.paused == true){
      this.confirmMessage  = this.translate.instant('hydroponics.location.startMeasureQuestion');
    }
  }

  acceptStatusChange(){
    if (this.selectedNode.nodeType == NodeType.LOCATION) {
      let request : LocationStatusRequest = {locationId: this.selectedNode.id, status: this.selectedNode.paused ? ACTIVE : PAUSED};
      this.locationManagementImplService.setLocationStatus({
        body: request
      }).subscribe(() => {
        this.saveEvent(NodeType.LOCATION);
        this.ngOnInit();
      });
    } else if(this.selectedNode.nodeType == NodeType.DEVICE){
      let request : DeviceStatusRequest = {deviceId: this.selectedNode.id, status: this.selectedNode.paused ? ACTIVE : PAUSED};
      this.deviceManagementImplService.setDeviceStatus({
        body: request
      }).subscribe(() => {
        this.saveEvent(NodeType.DEVICE);
        this.ngOnInit();
      });
    }
  }

  saveEvent(type: NodeType) {
    let saveEventDataRequest = {} as SaveEventDataRequest;
    saveEventDataRequest.description = this.eventText;
    saveEventDataRequest.eventTypeName = "Measure change";

    if (type == NodeType.LOCATION){
      saveEventDataRequest.locationId = this.selectedNode.id
    } else if(type == NodeType.DEVICE){
      saveEventDataRequest.deviceId = this.selectedNode.id
    }

    this.eventService.saveEvent({body: saveEventDataRequest}).subscribe();
  }

  private buildDeviceLeaves(deviceToLocations: Array<DeviceToLocationDto>,
                            devices: NodeElement[],
                            deviceSumValues: {alerts: number, worksheets: number },
                            deviceAdditions: {[p: string]: DeviceAdditionDto},
                            profileEditable,
                            locationsLabel: string,
                            settlementLabel: string) {
    for (let deviceToLoc of deviceToLocations) {
      if (deviceToLoc.device) {
        let device = deviceToLoc.device;
        if (device.deviceType) {
          let type = device.deviceType.type
          if (this.deviceTypes.includes(DeviceTypeEnum[type])) {
            let devAddition = deviceAdditions ? deviceAdditions[device.deviceId] : null;

            let deviceNode: NodeElement = {
              id: device.deviceId,
              nodeType: NodeType.DEVICE,
              label: deviceToLoc.device.deviceMac ? deviceToLoc.device.name + " (" + deviceToLoc.device.deviceMac + ")" : deviceToLoc.device.name,
              paused: device.status == PAUSED,
              worksheets: devAddition ? devAddition.worksheetCount : 0,
              alerts: devAddition ? devAddition.deviceAlertsCountDTO.sum : 0,
              chartAlerts: devAddition ? devAddition.deviceAlertsCountDTO : null,
              plantProfile: device.plantProfile ? device.plantProfile : undefined,
              type: NodeRoleType.LEAF,
              defaultProfile: true,
              profileEditable: profileEditable
            }

            deviceNode.deviceName = deviceNode.label;
            deviceNode.settlement = settlementLabel;
            deviceNode.locations = locationsLabel;

            deviceSumValues.alerts += deviceNode.alerts;
            deviceSumValues.worksheets += deviceNode.worksheets;
            devices.push(deviceNode)
          }
        }
      }
    }
  }

  private loadPlantProfile() {
    this.loadPlantProfileSubject.next();
    this.dialogVisible = true;
  }

  onPlantProfileSave(event: PlantProfileSaveEvent){
    if (this.selectedNode){
      let request : PlantProfileSaveRequest = {
        plantProfileId: event.selectedProfile ? event.selectedProfile.id : null,
        plantProfileAttributes: event.defaultProfile == true ? null : event.profileAttributes
      }

      if (this.selectedNode.nodeType == NodeType.LOCATION){
        request.locationId = this.selectedNode.id;
        this.locationManagementImplService.savePlantProfileToLocation({ body: request })
          .subscribe(() => {
            this.ngOnInit();
          });
      } else  if (this.selectedNode.nodeType == NodeType.DEVICE){
        request.deviceId = this.selectedNode.id;
        this.locationManagementImplService.savePlantProfileToDevice({ body: request })
          .subscribe(() => {
            this.ngOnInit();
          });
      }
    }
  }

  getProfileType() {
    if (this.selectedNode && this.selectedNode.nodeType == NodeType.LOCATION){
      return ProfileType.LOCATION
    } else if (this.selectedNode && this.selectedNode.nodeType == NodeType.DEVICE){
      return ProfileType.DEVICE
    }
  }

  private jumpToWS() {
    this.router.navigate([`/` + WORKSHEET, this.selectedNode.nodeType, this.selectedNode.id]);
  }

  private jumpToErrors() {
    this.locationTreeService.setSelectedNode(this.selectedNode);
    this.router.navigate([`/` + SENSORS_ROUTE]);
  }
}
